import styled from 'styled-components';

export const ModalCardStyled = styled.div`
  background: #ffffff;
  border-radius: 10.2317px;

  .heading {
    padding: 1.5rem;
    .closeBtn {
      margin-top: -30px;
      cursor: pointer;
    }
  }
  .nodata{
    align-items: center;
    height: 5rem;
  }

  .avatar-circle{
    width: 40px;
    height: 40px;
    background-color: rgb(198, 238, 244);
    border-radius: 50%;
    color: rgb(18, 178, 203);
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    line-height: 38px;
  }
  .avatar-name{
    padding-left: 5px;
    align-self: center;
  }
  .rating-display{
    width: 53.38px;
    height: 28px;
    background: #FFF5E0;
    border: 0.875px solid #F7B028;
    box-shadow: 2.625px 3.5px 3.5px rgba(221, 241, 244, 0.1);
    border-radius: 3.5px;
  }
  .rate-display{
    line-height: 18px;
    padding-left: 5px;
  }
  .title {
    font-weight: 600;
    font-size: 24.5561px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #353535;
  }
  position: relative;
  .closeBtn {
    position: absolute;
    right: 0 !important;
  }
  .bankDetails {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 26px;
    width: 92%;
    min-height: 67px;
    background: #fafafa;
    border-radius: 4.09269px;
    color: #04021d;
  }
  .colFirst {
    margin-top: 10px;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
    color: #04021d;
    margin-right: 15px;
  }
  .colSecond {
    margin-top: 10px;
    font-weight: 500;
    font-size: 16px;
    color: #686777;
  }
  .colFirst user{
    margin-left:10px;

  }
  .rating{
    margin-top:30px;
    margin-left: 8px;
    width: 97%;
  }
  .userDetails {
    margin-left: 28px;
    width: 92%;
    background: #FAFAFA;
    border-radius: 5.11586px;
    border: 0.511586px solid rgba(151, 151, 151, 0.54);
    margin-top: 20px;
    
  }
  .star{
    margin-left:-13px !important;
  }
  .nameDetails{
    margin-left:-2rem !important;
  }
  .bauerating{
    font-size: 12px;
  }
  .review {
    padding: 1rem;
    margin-left: 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #04021d;
  }
  Button {
    border: 0.511586px solid #12b2cb !important;
    margin-left: 28px;
    width: 95%;
    background: #12b2cb !important;
    border-radius: 4.09269px;
    height: 28.28px;
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 12.2317px;  
  }
  .outterRing {
    width: 58px;
    height: 57px;
    border: 1px solid #dae1e7;
    border-radius: 50%;
    .innerRing {
      background: #12b2cb;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      position: relative;
      top: 2.9px;
      left: 2.7px;
      text-align: center;
      span {
        font-weight: 600;
        font-size: 26px;
        color: #ffffff;
        position: relative;
        top: 4px;
      }
    }
`;
