import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import { getRating } from '../../../redux/actions/rateCollection';
import { CLOSE_BUTTON, Star, START_RATE } from '../../../assets/images';
import { formatRating } from '../../../utils';
import Loader from '../../Loader/Loader';
import { ModalCardStyled } from './ModalCardStyled';

const ModalCard = (props) => {
  const initialValues = {
    bankLogo: '',
    bankName: '',
    apy: '',
    starRating: '',
    maxBalance: '',
    minBalance: '',
    name: '',
    review: '',
    bankId: { bankName: '', bankLogo: '', bankId: '' }
  };

  const [details, setDetails] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    if (props.data.notificationType === 'ratingsAndReviews') {
      getRating(props?.data?.typeId).then((res) => {
        const result = { ...res.data, bankId: { ...res.data.bankId, bankLogo: '' } };
        setDetails(result);
        setIsLoading(false);
      });
    } else {
      setDetails(initialValues);
      setIsLoading(false);
    }
  }, [props?.data?.typeId]);

  const getFirtLatters = (nameStr = '') => {
    const nameArr = nameStr.split(' ');
    let retResult = '';
    for (let i = 0; i < nameArr.length; i++) {
      if (i === 2) break;
      retResult += nameArr[i][0];
    }
    return retResult;
  };
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
      <Modal.Body className="body-scroll">
        <ModalCardStyled>
          <div className="heading">
            <span className="title">{props.data.message}.</span>
            <span onClick={props.onHide} className="closeBtn">
              <img src={CLOSE_BUTTON} alt="profile" />
            </span>
          </div>
          <Loader isLoading={isLoading}>
            <Container>
              {details.bankName !== '' && (
                <Row>
                  <Row className="d-flex justify-content-space-evenly align-items-center"></Row>
                  <Row className="bankDetails">
                    <Col xs={3} sm={3} md={1} className="colFirst">
                      <img
                        src={details?.bankId?.bankLogo ? details?.bankId?.bankLogo : '/image/defaultIcon.png'}
                        alt="profile"
                        layout="fill"
                        objectFit="contain"
                        width={40}
                        height={40}
                      />
                    </Col>
                    <Col xs={3} sm={3} md={4} className="colFirst">
                      <Row>{details?.bankId?.bankName}</Row>
                      <Row>
                        {details?.bankId?.starRating && (
                          <div>
                            <span className="star">
                              <img src={Star} width={14} height={14} alt="Star" /> {formatRating(details?.bankId?.starRating)}
                            </span>
                            <span className="bauerating"> BauerFinancial Star Rating</span>
                          </div>
                        )}
                      </Row>
                    </Col>
                    <Col xs={3} sm={3} md={1} className="colSecond">
                      <Row>APY</Row>
                      <Row>{details?.apy}</Row>
                    </Col>
                    <Col xs={3} sm={3} md={3} className="colSecond">
                      <Row>Min. Deposit</Row>
                      <Row>${details?.minBalance}</Row>
                    </Col>
                    <Col xs={3} sm={3} md={2} className="colSecond">
                      <Row>Max. Deposit</Row>
                      <Row>${details?.maxBalance}</Row>
                    </Col>
                  </Row>
                  {props.data.notificationType === 'ratingsAndReviews' && (
                    <>
                      <Row className="rating">
                        <Col>
                          <div className="d-flex">
                            <div className="avatar-circle">{getFirtLatters(details?.name)}</div>
                            <div className="avatar-name">{details?.name}</div>
                          </div>
                          {/* <Rating  />{' '} */}
                        </Col>
                        <Col className="d-flex pt-1 justify-content-end">
                          <div className="rating-display d-flex p-1">
                            <img src={START_RATE} width={15} alt="" />
                            <div className="rate-display">{details?.ratings}</div>
                          </div>
                          {/* <Rating  />{' '} */}
                        </Col>
                      </Row>
                      <Row className="userDetails">
                        <Col className="review">
                          <Row>{details?.review}</Row>
                        </Col>
                      </Row>
                    </>
                  )}
                </Row>
              )}
              {details.bankName === '' && (
                <Row className="container nodata">
                  <Col className="text-center">This CD is no longer available</Col>
                </Row>
              )}
            </Container>
          </Loader>
        </ModalCardStyled>
      </Modal.Body>
    </Modal>
  );
};

ModalCard.propTypes = {
  onHide: PropTypes.func,
  message: PropTypes.string,
  id: PropTypes.string,
  data: PropTypes.arrayOf(Object)
};

export default ModalCard;
