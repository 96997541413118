import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiEyeOff, FiEye } from 'react-icons/fi';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { BTButtonWithLoader } from '../../components/Buttons';
import { changeUserPassword } from '../../redux/actions/auth';
import { successHandling } from '../../utils';
import BreadCrumb from '../../components/Breadcrumbs';
import { ProfileStyled } from './Profile.style';

const ProfileChangePassword = () => {
  const { userData } = useSelector((state) => state.AuthReducer);
  const [passOErr, setPassOErr] = useState('');
  const [passCErr, setPassCErr] = useState('');
  const [passErr, setPassErr] = useState('');
  const [tgOldPass, setTGOldPass] = useState(false);
  const [tgNewPass, setTGNewPass] = useState(false);
  const [tgCNewPass, setTGCNewPass] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [passwordValue, setPasswordValidation] = useState({
    uppercase: false,
    lowercase: false,
    specialChar: false,
    numeral: false,
    minchar: false,
    maxchar: false,
    valid: false
  });

  const [userProfile, setUserProfile] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const navigate = useNavigate();

  useEffect(() => {
    setUserProfile({ ...userProfile, ...{ userId: userData?._id } });
  }, [userData]);

  const handleOnchange = (e) => {
    setUserProfile({
      ...userProfile,
      [e.target.name]: e.target.value
    });
    if (userProfile.oldPassword.length > 0) {
      setPassOErr('');
    }
    if (userProfile.newPassword.length > 0) {
      setPassErr('');
    }
  };

  const save = () => {
    if (userProfile.oldPassword.length === 0) {
      setPassOErr('Please enter valid Old password');
      return;
    }
    if (
      !passwordValue.lowercase ||
      !passwordValue.uppercase ||
      !passwordValue.specialChar ||
      !passwordValue.numeral ||
      !passwordValue.minchar ||
      !passwordValue.maxchar
    ) {
      setPassErr('Please enter valid password');
      return;
    }
    if (userProfile.oldPassword === userProfile.newPassword) {
      setPassErr('New Password can not be same as Old Password');
      return;
    }
    if (userProfile.newPassword !== userProfile.confirmPassword) {
      setPassCErr('New Password and Confirm Password does not match');
      return;
    }

    setIsLoading(true);
    changeUserPassword(userProfile).then((res) => {
      if (res) {
        successHandling(res?.message);
        navigate('/profile');
      }
      setIsLoading(false);
    });
  };

  const checkPasswordHandler = (event) => {
    const pass = event.target.value;
    const lowerCaseLetters = /[a-z]/;
    if (pass.match(lowerCaseLetters)) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, lowercase: true };
      });
    } else {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, lowercase: false };
      });
    }
    // Validate capital letters
    const upperCaseLetters = /[A-Z]/;
    if (pass.match(upperCaseLetters)) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, uppercase: true };
      });
    } else {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, uppercase: false };
      });
    }

    // Validate a number
    const numbers = /\d/;
    if (pass.match(numbers)) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, numeral: true };
      });
    } else {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, numeral: false };
      });
    }
    // Validate minimum length
    if (pass.length >= 8) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, minchar: true };
      });
    } else {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, minchar: false };
      });
    }
    // Validate maximum length
    if (pass.length > 20 || pass.length < 8) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, maxchar: false };
      });
    } else {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, maxchar: true };
      });
    }
    // Validate Special Character
    const regex = /[-@#$%^&]/;
    if (pass.match(regex)) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, specialChar: true };
      });
    } else {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, specialChar: false };
      });
    }
  };
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header>Password must contain the following:</Popover.Header>
      <Popover.Body>
        <p id="letter" className={passwordValue.lowercase ? 'text-success' : 'text-danger'}>
          {' '}
          {passwordValue.lowercase ? <AiFillCheckCircle /> : <AiFillCloseCircle />} Must have 1 lower case letter
        </p>
        <p id="capital" className={passwordValue.uppercase ? 'text-success' : 'text-danger'}>
          {passwordValue.uppercase ? <AiFillCheckCircle /> : <AiFillCloseCircle />} Must have 1 upper case letter
        </p>
        <p id="number" className={passwordValue.numeral ? 'text-success' : 'text-danger'}>
          {passwordValue.numeral ? <AiFillCheckCircle /> : <AiFillCloseCircle />} Must have 1 numeric value
        </p>
        <p id="length" className={passwordValue.minchar ? 'text-success' : 'text-danger'}>
          {passwordValue.minchar ? <AiFillCheckCircle /> : <AiFillCloseCircle />} Minimum 8 characters required
        </p>
        <p id="length" className={passwordValue.maxchar ? 'text-success' : 'text-danger'}>
          {passwordValue.maxchar ? <AiFillCheckCircle /> : <AiFillCloseCircle />} Maximum of 20 characters only
        </p>
        <p id="specialchar" className={passwordValue.specialChar ? 'text-success' : 'text-danger'}>
          {passwordValue.specialChar ? <AiFillCheckCircle /> : <AiFillCloseCircle />} Must have 1 special character [-@#$%^&]
        </p>
      </Popover.Body>
    </Popover>
  );

  return (
    <ProfileStyled>
      <div className="container">
        <BreadCrumb breadData={[{ title: 'Dashboard', href: '/user' }, { title: 'Profile' }]} />
        <h1 className="text-start">Profile</h1>
        <div className="row mx-2 px-2 my-4 shadow-xl p-5 bg-white rounded">
          <div className="col-lg-4 text-start position-relative">
            <h3>Old Password</h3>
            <input
              className="form-control text-start"
              type={tgOldPass ? 'text' : 'password'}
              maxLength={20}
              onChange={handleOnchange}
              value={userProfile.oldPassword}
              name="oldPassword"
            />
            <span className="icon" onClick={() => setTGOldPass(!tgOldPass)}>
              {tgOldPass ? <FiEye /> : <FiEyeOff />}
            </span>
            {passOErr && <p class="text-danger">{passOErr}</p>}
          </div>
          <div className="col-lg-4 position-relative">
            <h3>New Password</h3>
            <OverlayTrigger trigger="focus" placement="bottom" overlay={popover}>
              <input
                className="form-control text-start"
                type={tgNewPass ? 'text' : 'password'}
                maxLength={20}
                onChange={(e) => {
                  checkPasswordHandler(e);
                  handleOnchange(e);
                }}
                value={userProfile.newPassword}
                name="newPassword"
              />
            </OverlayTrigger>
            <span className="icon" onClick={() => setTGNewPass(!tgNewPass)}>
              {tgNewPass ? <FiEye /> : <FiEyeOff />}
            </span>
            {passErr && <p class="text-danger">{passErr}</p>}
          </div>
          <div className="col-lg-4 position-relative">
            <h3>Confirm Password</h3>
            <input
              className="form-control"
              type={tgCNewPass ? 'text' : 'password'}
              maxLength={20}
              onChange={handleOnchange}
              value={userProfile.confirmPassword}
              name="confirmPassword"
            />
            <span className="icon" onClick={() => setTGCNewPass(!tgCNewPass)}>
              {tgCNewPass ? <FiEye /> : <FiEyeOff />}
            </span>
            {passCErr && <p class="text-danger">{passCErr}</p>}
          </div>
          <div className="row text-end my-4">
            <div className="col-lg-12">
              <Button
                variant="outline-warning"
                style={{ marginRight: 10 }}
                type="button"
                onClick={() => {
                  navigate('/profile');
                }}
              >
                Cancel
              </Button>{' '}
              <BTButtonWithLoader isLoading={isLoading} title={'Save'} onClick={save} />
            </div>
          </div>
        </div>
      </div>
    </ProfileStyled>
  );
};

export default ProfileChangePassword;
