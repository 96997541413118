import styled from 'styled-components';

export const ProfileStyled = styled.div`
  .profileimg {
    position: relative;
    margin-bottom: 20px;
    .editProfileClass {
      position: absolute;
      top: 118px;
      // right: 10px;
      left: 130px;
    }
  }
  span.icon {
    position: absolute;
    top: 48px;
    right: 19px;
  }
`;
