import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import PropsType from 'prop-types';

const BreadCrumbs = ({ breadData }) => {
  return (
    <Breadcrumb>
      {breadData.map((item, index) => {
        if (item.href) {
          return (
            <Breadcrumb.Item key={index} href="/user">
              {item.title}
            </Breadcrumb.Item>
          );
        } else {
          return (
            <Breadcrumb.Item key={index} active>
              {item.title}
            </Breadcrumb.Item>
          );
        }
      })}
    </Breadcrumb>
  );
};

BreadCrumbs.propTypes = {
  breadData: PropsType.array
};

export default BreadCrumbs;
