// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import store from './redux/store';

import { UPDATE_DEVICE_TOKEN } from './redux/reducer/notificationReducer';
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBR3WXKww8iX952IhUMr7e3QjsMYlD1fm8',
  authDomain: 'cdvalet-8369a.firebaseapp.com',
  projectId: 'cdvalet-8369a',
  storageBucket: 'cdvalet-8369a.appspot.com',
  messagingSenderId: '290529735127',
  appId: '1:290529735127:web:956abb91c58df57b0d1b92',
  measurementId: 'G-EVZPMBWNMQ'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
export const getFCMToken = () => {
  return getToken(messaging, { vapidKey: 'BIhuV8UIGxMTAaGfh1bu50OGMFWrDrmz_3vVhU-V-PTDRKVZTva8iQ1blwM8noLUpJqOynIOT3zWAJlBG3lKaFg' })
    .then((currentToken) => {
      if (currentToken) {
        store.dispatch({ type: UPDATE_DEVICE_TOKEN, payload: currentToken });
      }
    })
    .catch((err) => {
      console.warn('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
