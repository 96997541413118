const AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL || 'https://cdvalet-3000-dev1.azurewebsites.net/api/v1';
const USER_BASE_URL = process.env.REACT_APP_USER_BASE_URL || 'https://cdvalet-3002-dev1.azurewebsites.net/api/v1';
const RC_BASE_URL = process.env.REACT_APP_RC_BASE_URL || 'https://cdvalet-3001-dev1.azurewebsites.net/api/v1';
const NOTIFICATION_BASE_URL = process.env.REACT_APP_NOTIFICATION_BASE_URL || 'https://cdvalet-3003-dev1.azurewebsites.net/api/v1';
export const API_BI_URL = process.env.REACT_APP_AZ_BI_URL;
export const API_FI_LOGIN_URL = process.env.REACT_APP_AZ_LOGIN_URL;
export const API_FI_LOGOUT_URL = process.env.REACT_APP_AZ_LOGOUT_URL;

export const API_LOGIN = AUTH_BASE_URL + '/login';
export const API_LOGOUT = AUTH_BASE_URL + '/logout';
export const API_GET_USER_DETAILS = AUTH_BASE_URL + '/getUserDetails';

export const API_VERIFYOTP = AUTH_BASE_URL + '/otp';
export const API_UPDATE_PASSWORD = AUTH_BASE_URL + '/changePassword';
export const API_SAVE_USER = AUTH_BASE_URL + '/register';
export const API_REG_USER = AUTH_BASE_URL + '/register';
export const API_REG_USER_NEW = AUTH_BASE_URL + '/newregister';
export const API_RESEND_OTP = AUTH_BASE_URL + '/resend/otp';
export const API_UPDATE_PASSWORD_USER = AUTH_BASE_URL + '/update-password';
export const API_VERIFY_FORGOT_PASSWORD_OTP = AUTH_BASE_URL + '/verify-forgot-password-otp';

export const API_GET_USERS = USER_BASE_URL + '/userList';
export const API_GET_EXPORT_USER = USER_BASE_URL + '/export';
export const API_GET_USER_BY_ID = USER_BASE_URL + '/user';
export const API_UPDATE_USER = USER_BASE_URL + '/update/user';
export const API_DELETE_USER = USER_BASE_URL + '/delete/user';

export const API_RATE_COLLECTION = RC_BASE_URL + '/add/rate';
export const API_GET_RATE_COLLECTION = RC_BASE_URL + '/get/rate';
export const API_UPDATE_RATE_COLLECTION = RC_BASE_URL + '/update/rate';
export const API_DELETE_RATE_COLLECTION = RC_BASE_URL + '/delete/rate?id=';
export const API_EXPORT_RATE_COLLECTION = RC_BASE_URL + '/export';
export const API_FORGOT_PASSWORD = AUTH_BASE_URL + '/forgot-password';

export const API_GET_BANK_LIST = RC_BASE_URL + '/bank/list';
export const API_GET_NOTIFICATION_LIST = NOTIFICATION_BASE_URL + '/notification/fi/get/all';
export const API_DELETE_REVIEW = NOTIFICATION_BASE_URL + 'notification/fi/delete';
export const API_GET_RATING = AUTH_BASE_URL + '/get-rating';
export const API_GET_ALL_STATES = RC_BASE_URL + '/state/get';
export const API_GET_STATE_ABBRIVATION = RC_BASE_URL + '/state/get/abbrivation';
export const API_GET_BANK_LIST_BY_ID = RC_BASE_URL + '/bank/listWithStatesById';
export const API_GET_CD_RATE_NOTIFICATION = RC_BASE_URL + '/rate/id';
