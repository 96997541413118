import { getDataFromCookie, setDataToCookie } from '@sbcd/wrapper/core/utils/storage/cookieHelper';
export const LOGIN = 'auth/LOGIN';
export const VERIFY_OTP = 'auth/VERIFY_OTP';
export const LOGOUT = 'auth/LOGOUT';
export const LOADING = 'auth/LOADING';
export const USER_DATA = 'auth/USER_DATA';
export const FORGOT_PWD_DATA = 'auth/FORGOT_PWD_DATA';
export const SET_FORGOT_PWD = 'auth/SET_FORGOT_PWD';
export const SET_REVIEW_COUNT = 'auth/SET_REVIEW_COUNT';

const userData = JSON.parse(getDataFromCookie('userData'));
const initialState = {
  isLogin: !!getDataFromCookie('accessToken'),
  userData: userData,
  isLoading: false,
  isForgotPwd: false,
  reviewCount: 0
};

export default function AuthReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        userData: action?.payload
      };
    case LOGOUT:
      return {
        ...state,
        isLogin: false,
        userData: {}
      };
    case VERIFY_OTP:
      return {
        ...state,
        isLogin: true
      };
    case LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading
      };
    case USER_DATA:
      setDataToCookie('userData', JSON.stringify({ ...state.userData, ...action?.payload }));
      return {
        ...state,
        userData: { ...state.userData, ...action?.payload }
      };
    case FORGOT_PWD_DATA:
      setDataToCookie('userData', JSON.stringify({ ...state.userData, ...action?.payload }));
      return {
        ...state,
        forgotPassword: { ...state.userData, ...action?.payload },
        userData: { ...state.userData, ...action?.payload }
      };
    case SET_FORGOT_PWD:
      return {
        ...state,
        isForgotPwd: action.payload.isForgotPwd
      };
    case SET_REVIEW_COUNT:
      return {
        ...state,
        reviewCount: action.payload
      };
    default:
      return state;
  }
}
