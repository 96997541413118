/* eslint-disable comma-dangle */
import React from 'react';
import PropsType from 'prop-types';
import { WithLoaderStyled, InnerLoaderStyled } from './WithLoaderStyled';

const Loader = ({ isLoading, children, loadingText = 'Loading...', height = 300 }) => {
  const getClassName = () => {
    const className = height < 50 ? 'small-spinner' : 'bigger-spinner';
    return isLoading ? 'fadeIn ' + className : 'fadeOut ' + className;
  };

  return (
    <WithLoaderStyled style={isLoading ? { minHeight: height } : {}}>
      <InnerLoaderStyled className={getClassName()}>
        <div>
          <span className="spinner-border spinner-border-md pr-1" role="status" aria-hidden="true"></span>
          <span className="sr-only"> {loadingText}</span>
        </div>
      </InnerLoaderStyled>
      <div className={isLoading ? 'bluredBG' : ''}>{children}</div>
    </WithLoaderStyled>
  );
};
Loader.propTypes = {
  isLoading: PropsType.bool.isRequired,
  children: PropsType.node.isRequired,
  loadingText: PropsType.string,
  height: PropsType.number
};

Loader.defaultProps = {
  loadingText: 'Loading...',
  height: 300
};

export default Loader;
