import React, { useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import { VscBell } from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import './Header.css';
import { getReviews } from '../../redux/actions/rateCollection';

export default function Header() {
  const { userData, reviewCount } = useSelector((state) => state.AuthReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [tableState] = useState({
    userId: userData._id,
    pageNumber: 1,
    pageSize: 5
  });

  const fetchData = () => {
    dispatch(getReviews(tableState));
  };

  useEffect(() => {
    fetchData();
  }, [userData]);

  return (
    <div className="wrapper">
      <Navbar>
        <Navbar.Brand href="/">&nbsp;{userData?.username}</Navbar.Brand>
      </Navbar>
      <div className="wrapper-container">
        <button title="Notifications" alt="Notifications" className="bell-icon" onClick={() => navigate('/notification')}>
          <VscBell />
        </button>
        {reviewCount > 0 && <span className="notificationDot">{reviewCount}</span>}
        {/*
        settings icon
        <button className="bell-icon">
          <AiOutlineSetting />
        </button> */}
        {/* <a href="/profile" alt="Profile" title="Profile"> */}
        <img
          src={userData?.path ? userData?.path : '/logo192.png'}
          alt="office"
          className="user-image"
          onClick={() => navigate('/profile')}
        />
        {/* </a> */}
      </div>
    </div>
  );
}
