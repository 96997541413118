/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable multiline-ternary */
import React from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import PropTypes from 'prop-types';
import './PaginationNew.css';

const PaginationNew = ({ ratePerPage, totalRates, paginate, currentPage }) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalRates / ratePerPage); i++) {
    pageNumbers.push(i);
  }
  const pages = [...Array(Math.ceil(totalRates / ratePerPage)).keys()];
  let startPage = 1;
  let endPage = 5;
  if (currentPage >= 3) {
    startPage = currentPage - 2;
  }

  if (pages.length < endPage) {
    endPage = pages.length;
  } else {
    endPage = startPage + 4;
    if (pages.length < endPage) {
      endPage = pages.length;
      if (pages.length - 4 > 0) {
        startPage = pages.length - 4;
      }
    }
  }

  const getPagesCompo = () => {
    const pageCompo = [];
    for (let i = startPage; i <= endPage; i++) {
      if (i === currentPage) {
        pageCompo.push(
          <li key={i} className={i === currentPage ? 'page-item active' : 'page-item'} tabIndex={i}>
            <a className="page-link" onClick={() => paginate(i)}>
              {i}
            </a>
          </li>
        );
      } else {
        pageCompo.push(
          <li key={i} className={i === currentPage ? 'page-item active' : 'page-item'}>
            <a className="page-link" onClick={paginate.bind(this, i)}>
              {i}
            </a>
          </li>
        );
      }
    }
    return pageCompo;
  };
  return (
    <nav className="pagination-outer" aria-label="...">
      <ul class="pagination">
        <li class={currentPage === 1 ? 'page-item disabled' : 'page-item'}>
          <a class="page-link" onClick={() => paginate(currentPage - 1)}>
            <span aria-hidden="true">
              <IoIosArrowBack />
            </span>
          </a>
        </li>
        {getPagesCompo()}
        <li className={currentPage === pageNumbers.length ? 'page-item disabled' : 'page-item'}>
          <a class="page-link" onClick={() => paginate(currentPage + 1)}>
            <span aria-hidden="true">
              <IoIosArrowForward />
            </span>
          </a>
        </li>
      </ul>
    </nav>
  );
};
PaginationNew.propTypes = {
  ratePerPage: PropTypes.number.isRequired,
  totalRates: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  paginate: PropTypes.func.isRequired
};
export default PaginationNew;
