import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, Button, Form, Stack, Breadcrumb, Row, Col } from 'react-bootstrap';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isAlphabetWithSpace, isNumber, isValidURL } from '@sbcd/wrapper/core/utils/helper/validation';
import { VALID_MAX_VALUE, VALID_URL } from '../../constant/messages';
import { successHandling, isBalanceValid, numberToLocalForm, removeCommafromString, isValidatePercentage } from '../../utils';
import { getStateListById, saveRateCollection } from '../../redux/actions/rateCollection';
import './RCManagement.css';

const AddRateCollection = () => {
  const { userData } = useSelector((state) => state.AuthReducer);
  const [deleteButtonVisible, setDeleteButtonVisible] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const initialValues = {
    name: userData?.bankId?.bankName,
    bankId: userData?.bankId?._id,
    termInMonths: '',
    apy: '',
    minBalance: '',
    maxBalance: '',
    url: '',
    checked: false,
    userId: userData._id
  };
  const [rates, setRates] = useState(1);
  const [enable, setEnable] = useState(true);
  const [, setIsLoading] = useState(false);
  const [isMaxErrArr, setIsMaxErrArr] = useState([false]);
  const [isURLErr, setIsURLErr] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isURLErrArr, setIsURLErrArr] = useState([false]);
  const [rateDetails, setRateDetails] = useState([initialValues]);
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const handleOnChange = (e, ind) => {
    const { name, value } = e.target;
    setRateDetails(() =>
      rateDetails.map((item, index) => {
        if (ind === index) {
          return { ...item, [name]: value };
        }
        return item;
      })
    );
  };

  const checkMinMaxErr = (e, index) => {
    if (
      (e.target.name === 'minBalance' && Number(rateDetails[index]?.maxBalance) < Number(e.target.value)) ||
      (e.target.name === 'maxBalance' && Number(rateDetails[index]?.minBalance) > Number(e.target.value))
    ) {
      const maxErrTmp = isMaxErrArr;
      maxErrTmp[index] = true;
      setIsMaxErrArr(maxErrTmp);
    } else {
      const maxErrTmp = isMaxErrArr;
      maxErrTmp[index] = false;
      setIsMaxErrArr(maxErrTmp);
    }
  };

  const deleteRateSection = (itemIndex) => {
    setRates((prev) => prev - 1);
    const rts = rateDetails.filter((item, index) => itemIndex !== index);
    setRateDetails(rts);
  };

  useEffect(() => {
    if (rates > 1) {
      setDeleteButtonVisible(true);
    } else {
      setDeleteButtonVisible(false);
    }
  }, [rates]);

  const handleSave = () => {
    const maxValue = rateDetails.every((item) => parseFloat(item.maxBalance) >= parseFloat(item.minBalance));
    const maxErrTmp = [];
    rateDetails.map((item) => {
      let isMax = false;
      if (parseFloat(item.maxBalance) < parseFloat(item.minBalance)) {
        isMax = true;
      }
      maxErrTmp.push(isMax);
      return item;
    });
    setIsMaxErrArr(maxErrTmp);

    if (maxValue && !isURLErr) {
      setIsURLErr(false);
      setIsLoading(true);
      dispatch(saveRateCollection(rateDetails)).then((res) => {
        if (res) {
          successHandling(res?.message);
          navigation('/ratecollection');
        }
      });
      setIsLoading(false);
    }
  };

  const handleAddNewRates = () => {
    setDeleteButtonVisible(true);
    setRateDetails([
      ...rateDetails,
      {
        ...initialValues
      }
    ]);
    setRates((prev) => prev + 1);
  };

  const handleOnToggle = (id) => {
    const prevURL = rateDetails.find((item, i) => i === id - 1 && item);
    setRateDetails(() =>
      rateDetails.map((item, index) => {
        if (id === index) {
          return { ...item, url: item.checked ? '' : prevURL.url, checked: !item.checked };
        }
        return item;
      })
    );
  };

  useEffect(() => {
    const validation = rateDetails.every(
      (item) =>
        item.apy !== '' &&
        item.maxBalance !== '' &&
        item.minBalance !== '' &&
        item.name !== '' &&
        item.termInMonths !== '' &&
        item.url !== ''
    );
    setEnable(!(validation && isChecked));
  }, [rateDetails, isChecked]);

  const [stateQuery] = useState({
    query: '',
    pageNumber: 1,
    pageSize: 100,
    specialStates: true
  });

  const fetchStateData = useCallback(() => {
    dispatch(getStateListById({ bankId: userData.bankId._id })).then((res) => {
      if (res) {
        setStateOptions(res?.data?.masterData);
      } else {
        setStateOptions([]);
      }
    });
  }, [dispatch, stateQuery]);

  const handleRemoveComma = (e, i) => {
    const val = removeCommafromString(e.target.value);
    e.target.value = val;
    if (isBalanceValid(val) || e.target.value === '') {
      handleOnChange(e, i);
    }
    if (e.target.name === 'maxBalance') {
      checkMinMaxErr(e, i);
    }
  };

  useEffect(() => {
    fetchStateData();
    return fetchStateData();
  }, [fetchStateData]);

  const getRow = (i) => {
    return (
      <div className="ratesList" key={i}>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <b>Rate {i + 1}</b>
            </Accordion.Header>
            <Accordion.Body>
              <form className="row g-3">
                <div className="col-md-6">
                  <label htmlFor="inputName" className="form-label">
                    Bank Name&nbsp;<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    readOnly
                    value={rateDetails[i].name}
                    id="inputName"
                    onChange={(e) => {
                      if (isAlphabetWithSpace(e.target.value) || e.target.value === '') {
                        handleOnChange(e, i);
                      }
                    }}
                    maxLength={20}
                  />
                </div>
                <div className="col-md-3">
                  <label htmlFor="inputName" className="form-label">
                    Charter State&nbsp;<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div>&nbsp;&nbsp;&nbsp;{userData.bankId.charterState || '--'}</div>
                </div>
                <div className="col-md-3">
                  <label htmlFor="inputName" className="form-label">
                    Marketed In&nbsp;<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div>&nbsp;&nbsp;&nbsp;{stateOptions?.join(', ') || '--'}</div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputTermInMonth" className="form-label">
                    Term in months&nbsp;<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="termInMonths"
                    value={rateDetails[i].termInMonths}
                    id="inputTermInMonths"
                    onChange={(e) => {
                      if (isNumber(e.target.value) || e.target.value === '') {
                        handleOnChange(e, i);
                      }
                    }}
                    maxLength={2}
                  />
                </div>
                <div className="col-6">
                  <label htmlFor="inputApy" className="form-label">
                    APY&nbsp;<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="apy"
                    value={rateDetails[i].apy}
                    id="inputApy"
                    maxLength={5}
                    onChange={(e) => {
                      if (isValidatePercentage(e.target.value) || e.target.value === '') {
                        handleOnChange(e, i);
                      }
                    }}
                  />
                </div>
                <div className="col-6">
                  <label htmlFor="inputMinBalance" className="form-label">
                    Min Balance&nbsp;<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="minBalance"
                    value={numberToLocalForm(rateDetails[i].minBalance)}
                    id="inputMinBalance"
                    onChange={(e) => {
                      handleRemoveComma(e, i);
                    }}
                    maxLength={11}
                    onBlur={(e) => checkMinMaxErr(e, i)}
                  />
                </div>
                <div className="col-6">
                  <label htmlFor="inputMaxBalance" className="form-label">
                    Max Balance&nbsp;<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="maxBalance"
                    value={numberToLocalForm(rateDetails[i].maxBalance)}
                    id="inputMaxBalance"
                    onChange={(e) => {
                      handleRemoveComma(e, i);
                    }}
                    maxLength={11}
                    onFocus={(e) => checkMinMaxErr(e, i)}
                  />
                  {isMaxErrArr[i] && <p className="text-danger">{VALID_MAX_VALUE}</p>}
                </div>
                <div className="col-6">
                  <label htmlFor="inputMaxBalance" className="form-label">
                    URL&nbsp;<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="url"
                    value={rateDetails[i].url}
                    id="inputUrl"
                    onChange={(e) => {
                      handleOnChange(e, i);
                      if (isValidURL(e.target.value) || e.target.value === '') {
                        setIsURLErr(false);
                        const arr = isURLErrArr;
                        arr[i] = false;
                        setIsURLErrArr(arr);
                      } else {
                        setIsURLErr(true);
                        const arr = isURLErrArr;
                        arr[i] = true;
                        setIsURLErrArr(arr);
                      }
                    }}
                  />
                  {isURLErrArr[i] && <p className="text-danger">{VALID_URL}</p>}
                  {i !== 0 && (
                    <Stack direction="horizontal" gap={3}>
                      <label htmlFor="inputUrlRate" className="form-label">
                        Use same URL as rate {i}
                      </label>
                      <div className="form-check">
                        <Form.Check type="switch" id={i} checked={rateDetails[i].checked} onChange={() => handleOnToggle(i)} />
                      </div>
                    </Stack>
                  )}
                </div>
                {deleteButtonVisible && (
                  <div className="col-6 text-right">
                    <label className="form-label">&nbsp;</label>
                    <br />
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={() => {
                        deleteRateSection(i);
                      }}
                    >
                      <span>
                        <FaTrashAlt />
                      </span>
                    </button>
                  </div>
                )}
              </form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <br />
      </div>
    );
  };

  return (
    <div className="container">
      <Breadcrumb>
        <Breadcrumb.Item href="/user">Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item href="/ratecollection">Rate Collection</Breadcrumb.Item>
        <Breadcrumb.Item active>Add new rate</Breadcrumb.Item>
      </Breadcrumb>
      <div className="add-new-rates">
        <h3>Add new rate</h3>

        {rateDetails.map((item, index) => getRow(index))}
        {/* {rows} */}

        <Row>
          <Col>
            <Button variant="outline-primary" onClick={handleAddNewRates}>
              {' '}
              <FaPlus /> Add new rate
            </Button>
          </Col>
        </Row>
      </div>
      <div className="bottomContainer">
        <div className="disclaimer">
          <Row className="m-3">
            <Col>
              <Row className="justify-content-flex-start align-item-center">
                <Col sm={1}>
                  <input className="disclaimerCheck" type="checkbox" checked={isChecked} onClick={() => setIsChecked(!isChecked)} />
                </Col>
                <Col sm={7}>
                  <div className="disclaimerLabel">By clicking,</div>
                </Col>
              </Row>
              <Row className="mt-3">
                <div className="disclaimerContent">
                  I acknowledge and agree that I have read and understand the terms and conditions of the CD Valet website.
                  <br /> <br />
                  I further acknowledge and agree that (a) the information that I am submitting will be reviewed by CD Valet team at Seattle
                  Bank , (b) my submission of such information does not guarantee its inclusion on the CD Valet website , (c) CD Valet team
                  at Seattle Bank may accept or reject such information for inclusion on the CD Valet website in its sole discretion and (d)
                  if the information that I submit is accepted for inclusion on the CD Valet website, it will be displayed in a manner for
                  users of the CD Valet website , and displayed for a duration, as CD Valet team at Seattle Bank determines in its sole
                  discretion. <br />
                  <br />I hereby authorize the public display of, and grant CD Valet team at Seattle Bank the right to publicly display
                  through the CD Valet website all information that I am submitting as contemplated hereby.
                </div>
              </Row>
            </Col>
          </Row>
        </div>
        <Row>
          <Col className="d-flex justify-content-end mb-4">
            <Button variant="outline-primary" onClick={() => navigation('/ratecollection')}>
              &nbsp;Cancel&nbsp;
            </Button>
            <Button variant="primary" className="ml-3" onClick={handleSave} disabled={enable}>
              &nbsp;&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;
            </Button>{' '}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddRateCollection;
