import { apiCall } from '@sbcd/wrapper/core/utils/api/api';
import { errorHandling, resultHandling } from '../../utils';
import {
  API_GET_USERS,
  // API_REG_USER,
  API_SAVE_USER,
  API_UPDATE_USER,
  API_GET_USER_BY_ID,
  API_FORGOT_PASSWORD,
  API_VERIFY_FORGOT_PASSWORD_OTP,
  API_UPDATE_PASSWORD_USER,
  API_GET_BANK_LIST,
  API_GET_STATE_ABBRIVATION,
  API_REG_USER_NEW
} from '../../constant/api';
import { FORGOT_PWD_DATA } from '../reducer/authReducer';
import { getAuthHeader } from './auth';

export const getUser = (params = {}) => {
  return apiCall({ apiEndPoint: API_GET_USERS, requestType: 'POST', apiPostData: params, header: getAuthHeader() })
    .then((res) => {
      return resultHandling(res);
    })
    .catch((err) => {
      errorHandling(err);
    });
};

export const saveUser = (params) => {
  return apiCall({ apiEndPoint: API_SAVE_USER, requestType: 'POST', apiPostData: params, header: getAuthHeader() })
    .then((res) => {
      return resultHandling(res);
    })
    .catch((err) => {
      errorHandling(err);
    });
};

export const updateUserProfile = (params) => () => {
  const header = getAuthHeader();
  delete header['Content-Type'];

  return apiCall({ apiEndPoint: API_UPDATE_USER, requestType: 'POST', apiPostData: params, header: header, form: true })
    .then((res) => {
      return resultHandling(res);
    })
    .catch((err) => {
      errorHandling(err);
    });
};

export const registerUser = (params) => () => {
  return apiCall({ apiEndPoint: API_REG_USER_NEW, requestType: 'POST', apiPostData: params })
    .then((res) => {
      return resultHandling(res);
    })
    .catch((err) => {
      errorHandling(err);
    });
};

export const getFiUserById = (id) => {
  return apiCall({ apiEndPoint: API_GET_USER_BY_ID + '?id=' + id, requestType: 'GET', header: getAuthHeader() })
    .then((res) => {
      return resultHandling(res);
    })
    .catch((err) => {
      errorHandling(err);
    });
};

export const forgotPassword = (params) => (dispatch) => {
  return apiCall({ apiEndPoint: API_FORGOT_PASSWORD, requestType: 'POST', apiPostData: params })
    .then((res) => {
      const result = res?.response;
      if (result?.code !== 200) {
        errorHandling(result?.message);
      } else {
        dispatch({
          type: FORGOT_PWD_DATA,
          payload: { ...result?.data, phone: params.phone }
        });
        return result;
      }
    })
    .catch((err) => {
      errorHandling(err);
    });
};

export const verifyForgotPasswordOtp = (params) => (dispatch) => {
  return apiCall({ apiEndPoint: API_VERIFY_FORGOT_PASSWORD_OTP, requestType: 'POST', apiPostData: params })
    .then((res) => {
      const result = res?.response;
      if (result?.code !== 200) {
        errorHandling(result?.message);
      } else {
        dispatch({
          type: FORGOT_PWD_DATA,
          payload: result?.data
        });
        return result;
      }
    })
    .catch((err) => {
      errorHandling(err);
    });
};

export const updatePassword = (params) => () => {
  return apiCall({ apiEndPoint: API_UPDATE_PASSWORD_USER, requestType: 'POST', apiPostData: params }).then((res) => {
    return resultHandling(res);
  });
};

export const getBankList = (params) => {
  return apiCall({ apiEndPoint: API_GET_BANK_LIST, requestType: 'POST', apiPostData: params }).then((res) => {
    return resultHandling(res);
  });
};

export const getUserStateAbb = (params) => () => {
  return apiCall({ apiEndPoint: API_GET_STATE_ABBRIVATION, requestType: 'POST', apiPostData: params }).then((res) => {
    return resultHandling(res);
  });
};
