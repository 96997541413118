import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { formatPhoneNumber } from '@sbcd/wrapper/core/utils/helper';
import { isAlphaNumericWithSpace, isAlphaNumeric, isValidateEmail } from '@sbcd/wrapper/core/utils/helper/validation';
import { updateUserProfile, getFiUserById } from '../../redux/actions/users';
import { successHandling } from '../../utils';
import { BTButtonWithLoader } from '../../components/Buttons';
import BreadCrumb from '../../components/Breadcrumbs';
import { USER_DATA } from '../../redux/reducer/authReducer';
import { EDIT_IC } from '../../assets/images';
import { ProfileStyled } from './Profile.style';

const EditProfile = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.AuthReducer);
  const [emailErr, setEmailErr] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState({ preview: userData?.path ? userData?.path : '/logo192.png', raw: '' });

  const [userDataLocal, setUserDataLocal] = useState({
    role: 'fiuser',
    name: '',
    username: '',
    email: '',
    phone: '',
    bankName: ''
  });
  const navigate = useNavigate();

  useEffect(() => {
    setUserDataLocal({
      _id: userData?._id,
      role: 'fiuser',
      name: userData?.name,
      username: userData?.username,
      email: userData?.email,
      phone: userData?.phone,
      bankName: userData?.bankId?.bankName
    });
    setImage({ preview: userData?.path ? userData?.path : '/logo192.png', raw: '' });
  }, [userData]);

  const handleImageChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }
  };

  const handleOnchange = (e) => {
    setUserDataLocal({
      ...userDataLocal,
      [e.target.name]: e.target.value
    });
  };

  const updateProfileUser = () => {
    if (!isValidateEmail(userDataLocal.email)) {
      setEmailErr('Please enter valid email');
      return false;
    }
    setIsLoading(true);

    const params = userDataLocal;

    const formData = new FormData();

    for (const key in params) {
      formData.append(key, params[key]);
    }

    if (image.raw) {
      formData.append('profilePic', image.raw, image.raw?.name);
    }
    dispatch(updateUserProfile(formData)).then((res) => {
      if (res) {
        fetchUserData();
        successHandling('User Profile updated successfully');
        setTimeout(() => {
          navigate('/profile');
        }, 1000);
      }
      setIsLoading(false);
    });
  };

  const fetchUserData = () => {
    getFiUserById(userData._id).then((res) => {
      if (res) {
        dispatch({
          type: USER_DATA,
          payload: { ...res.data, bankId: userData.bankId }
        });
      }
    });
  };
  const formatNumber = (number) => {
    return formatPhoneNumber(number.substr(number.length - 10));
  };

  return (
    <ProfileStyled>
      <div className="container">
        <BreadCrumb breadData={[{ title: 'Dashboard', href: '/user' }, { title: 'Profile' }]} />
        <h1 className="text-start">Profile</h1>
        <div className="row mx-2 px-2 my-4 shadow-xl p-4 bg-white rounded ">
          <div className="col-lg-2 text-start profileimg">
            <img src={image ? image.preview : '/logo192.png'} width="135" height="135" alt="person"></img>
            <label htmlFor="upload-button" className="btn btn-info btn-sm editProfileClass">
              {/* <button className="btn btn-info btn-sm editProfileClass"> */}
              <img src={EDIT_IC} alt="profile" />
              {/* </button> */}
            </label>
            <input type="file" id="upload-button" style={{ display: 'none' }} onChange={handleImageChange} />
          </div>
          <div className="col-lg-10">
            <div className="row">
              <div className="col-lg-3 text-start">
                <h4>FI Name</h4>
                <input
                  className="form-control"
                  maxLength={20}
                  onChange={(e) => {
                    if (isAlphaNumericWithSpace(e.target.value) || e.target.value === '') {
                      handleOnchange(e);
                    }
                  }}
                  value={userDataLocal?.name}
                  name="name"
                />
              </div>
              <div className="col-lg-3 text-start">
                <h4>Bank Name</h4>
                <input
                  className="form-control"
                  maxLength={20}
                  readOnly
                  onChange={(e) => {
                    if (isAlphaNumeric(e.target.value) || e.target.value === '') {
                      handleOnchange(e);
                    }
                  }}
                  value={userDataLocal?.bankName}
                  name="username"
                />
              </div>
              <div className="col-lg-4 text-start">
                <h4>Email</h4>
                <input
                  className="form-control"
                  onChange={(e) => {
                    setEmailErr('');
                    handleOnchange(e);
                  }}
                  value={userDataLocal?.email}
                  name="email"
                />
                {emailErr && <p class="text-danger">{emailErr}</p>}
              </div>
            </div>
            <br></br>
            <div className="row my-3">
              <div className="col-lg-3 text-start">
                <h4>Phone number</h4>
                <h4>{formatNumber(userDataLocal?.phone)}</h4>
              </div>
              <div className="col-lg-3 text-start">
                <h4>Password</h4>
                <h4>***************</h4>
              </div>
            </div>
          </div>
          <div className="row text-end">
            <div className="col-lg-12">
              <Button
                variant="outline-warning"
                style={{ marginRight: 10 }}
                type="button"
                onClick={() => {
                  navigate('/profile');
                }}
              >
                Cancel
              </Button>{' '}
              <BTButtonWithLoader isLoading={isLoading} title={'Save'} onClick={updateProfileUser} />
            </div>
          </div>
        </div>
      </div>
    </ProfileStyled>
  );
};

export default EditProfile;
