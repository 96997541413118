export const VALID_PASSWORD = 'Please enter valid password.';
export const VALID_PASSWORD_MATCH = 'Password and Confirm Password does not match.';
export const VALID_PHONE_NUMBER = 'Phone number must have 10 digits.';
export const VALID_PWD = 'Password must have minimum 8 characters.';
export const VALID_TERMS_CONDITIONS = 'You should accept all terms and conditions.';
export const VALID_REG_MSG = 'Thank you for registering.';
export const VALID_URL = 'Please enter valid URL.';
export const DELETE_MSG = 'Are you sure you want to delete the user permanently?';
export const VALID_MAX_VALUE = 'Maximum value should be greater than or equal to mininum value.';
export const TERMS_CONDITIONS = 'Terms & Conditions';
export const FIELD_REQUIRED = 'This field is required';
export const SAVE_CONFIRM_MSG = 'Are you sure want to save changes?';
export const SAVE_CONFIRM_TITLE = 'Confirm Save';
