/* eslint-disable multiline-ternary */
import { getLocalStorageData, setLocalStorageData } from '@sbcd/wrapper/core/utils/storage/localStorageHelper';
import React, { useEffect, useState } from 'react';
import { Col, Container, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PaginationNew from '../RCManagement/PaginationNew';
import { getFirstLetters, successHandling } from '../../utils';
import { delReviews, getReviews } from '../../redux/actions/rateCollection';
import Loader from '../Loader/Loader';
import { NotificationMessage } from '../../constant';
import { NotificationContainer, NotificationWrapper, NotificationStyle } from './NotificationStyle';
import ModalCard from './Modal/ModalCard';

const Notification = () => {
  const [showModal, setShowModal] = useState(false);
  const [reviewData, setReviewData] = useState('');
  // const [reviewMsg, setReviewMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const [setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { userData, reviewCount } = useSelector((state) => state.AuthReducer);
  const { notificationData } = useSelector((state) => state.NotificationReducer);
  const navigation = useNavigate();

  const [tableState, setTableState] = useState({
    userId: userData._id,
    pageNumber: 1,
    pageSize: 5
  });

  const fetchData = () => {
    setIsLoading(true);
    dispatch(getReviews(tableState)).then((res) => {
      setIsLoading(false);
    });
  };

  const updatePageSize = (pageSize) => {
    const pageNumber = 1;

    setTableState({
      ...tableState,
      pageSize,
      pageNumber
    });
  };

  const storageKey = 'UserViewRecs';

  const getSelectedData = () => {
    let ids = JSON.parse(getLocalStorageData(storageKey));
    if (!ids) {
      ids = [];
    }
    return ids;
  };

  const paginate = (pageNumber) => {
    const selecteIds = [];
    const unSelectedIds = [];
    document.querySelectorAll('.rowCheckbox').forEach((e) => {
      const id = e.getAttribute('data-id');
      if (e.checked === true) {
        selecteIds.push(id);
      } else {
        unSelectedIds.push(id);
      }
    });

    let ids = getSelectedData();
    selecteIds.forEach((id) => {
      if (!ids.includes(id)) {
        ids.push(id);
      }
      return id;
    });
    ids = ids.filter((idItem) => {
      return !unSelectedIds.includes(idItem);
    });
    setLocalStorageData(storageKey, JSON.stringify(ids));
    setTableState({
      ...tableState,
      pageNumber
    });
  };

  const handleClick = (value) => {
    if (value.notificationType === 'fiInput') {
      navigation('/ratecollection');
    } else {
      setReviewData(value);
      setShowModal(true);
    }
  };

  const handleDelete = () => {
    // const id = notificationData.map((data) => data._id);
    const userId = userData._id;
    dispatch(delReviews({ userId })).then((res) => {
      if (res && res?.code === 200) {
        successHandling(res?.message);
      }
    });
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [tableState]);

  return (
    <NotificationContainer>
      &nbsp;
      {/* <BreadCrumbs breadData={[{ title: 'Dashboard', href: '/user' }, { title: 'Notification' }]} /> */}
      <Row className="w-100 flex-direct-row m-2">
        <Col className="justify-content-flex-start align-item-center p-0" sm={6}>
          <NotificationStyle>Notifications</NotificationStyle>
        </Col>
        <Col className="justify-content-end align-item-center d-flex" sm={6}>
          <span className="clearAll" onClick={handleDelete}>
            Clear All
          </span>
        </Col>
      </Row>
      <NotificationWrapper>
        <Loader isLoading={isLoading} height={100}>
          <div className="reviewTable">
            {notificationData ? (
              notificationData.map((data) => {
                return (
                  <>
                    <Container className="p-2" key={data._id} onClick={() => handleClick(data)}>
                      <Row>
                        <Col sm={1}>
                          <div className="outterRing">
                            <div className="innerRing">
                              <span>{getFirstLetters(userData?.bankId?.bankName)}</span>
                            </div>
                          </div>
                        </Col>
                        <Col sm={11} className="ps-4">
                          <Row className="heading">
                            <Col>{data.message}</Col>
                          </Row>
                          <Row className="subHeading">
                            <Col>{NotificationMessage[data.notificationType] ? NotificationMessage[data.notificationType] : ''}</Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                    <div className="horizontal mt-0"></div>
                  </>
                );
              })
            ) : (
              <div className="plainText p-5">No data to show.</div>
            )}
          </div>
        </Loader>
        <Row>
          <Col>
            {reviewCount > 5 && (
              <div className="row paginationRow mt-3">
                <div className="col-6">
                  <DropdownButton id="dropdown-button-dark-example2" title={'Rows per page:  ' + tableState.pageSize} variant="default">
                    <Dropdown.Item onClick={() => updatePageSize(5)}>5</Dropdown.Item>
                    <Dropdown.Item onClick={() => updatePageSize(10)}>10</Dropdown.Item>
                    <Dropdown.Item onClick={() => updatePageSize(20)}>20</Dropdown.Item>
                    <Dropdown.Item onClick={() => updatePageSize(reviewCount)}>All</Dropdown.Item>
                  </DropdownButton>
                </div>
                <div className="col-6 ">
                  <PaginationNew
                    ratePerPage={tableState.pageSize}
                    totalRates={reviewCount}
                    paginate={paginate}
                    currentPage={tableState.pageNumber}
                  />
                </div>
              </div>
            )}
          </Col>
        </Row>
      </NotificationWrapper>
      <ModalCard show={showModal} onHide={() => setShowModal(false)} data={reviewData} />
    </NotificationContainer>
  );
};

export default Notification;
