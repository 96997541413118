import React from 'react';
import { getDataFromCookie } from '@sbcd/wrapper/core/utils/storage/cookieHelper';
import { useNavigate, useLocation } from 'react-router-dom';
import { HiDocumentReport } from 'react-icons/hi';
import {
  // MENU_DASHBOARD,
  MENU_RATE_VALIDATION,
  MENU_USER
} from '../../assets/images/index';
import './SideBar.css';
import { API_BI_URL, API_FI_LOGOUT_URL } from '../../constant/api';

function SideBar() {
  const navigate = useNavigate();
  const route = useLocation();
  const goTo = (path, e) => {
    if (path === 'logout') {
      window.location.href = API_FI_LOGOUT_URL;
      // css here
      document.querySelectorAll('.sideContainer td').forEach((ele) => ele.classList.remove('active'));
      e.target.classList.add('active');

      // route here
      navigate(path);
    }
  };

  // const logout = () => {
  //   navigate('/logout');
  // };

  const redirecToBi = () => {
    const token = getDataFromCookie('accessToken');
    window.location.href = API_BI_URL + token;
  };
  return (
    <div className="sideContainer">
      <table>
        <tbody>
          <tr>
            <td>
              <img className="logoImg" src="/image/logo.png" alt="CD Valet" onClick={goTo.bind(this, '/')}></img>
            </td>
          </tr>
          {/* <tr>
            <td onClick={goTo.bind(this, '/user')} className={route.pathname === '/user' ? 'active' : ''}>
              <img src={MENU_DASHBOARD} alt="office" />
              &nbsp; Dashboard
            </td>
          </tr> */}
          <tr>
            <td onClick={goTo.bind(this, '/ratecollection')} className={route.pathname === '/ratecollection' ? 'active' : ''}>
              <img src={MENU_RATE_VALIDATION} alt="office" />
              &nbsp; Rate Collection
            </td>
          </tr>
          <tr>
            <td onClick={redirecToBi.bind(this)}>
              <HiDocumentReport />
              &nbsp; BI Dashboard
             </td>
          </tr>
          <tr>
            <td onClick={goTo.bind(this, 'logout')}>
              <img src={MENU_USER} alt="office" />
              &nbsp; Logout
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default SideBar;
