import PropsType from 'prop-types';

const ButtonWithLoader = ({ isDisabled = false, isLoading = false, title, loadingTitle = 'Loading...', type = 'submit', onClick = '' }) => {
  return (
    <button type={type} className="text-center pr-0" disabled={isLoading || isDisabled} onClick={onClick}>
      {isLoading && (
        <>
          <span className="spinner-border spinner-border-sm pr-1" role="status" aria-hidden="true"></span>
          <span className="sr-only"> {loadingTitle}</span>
        </>
      )}
      {!isLoading && <>{title}</>}
    </button>
  );
};

ButtonWithLoader.propTypes = {
  isLoading: PropsType.bool,
  isDisabled: PropsType.bool,
  title: PropsType.string.isRequired,
  loadingTitle: PropsType.string,
  type: PropsType.string,
  onClick: PropsType.func
};

export default ButtonWithLoader;
