import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { formatPhoneNumber } from '@sbcd/wrapper/core/utils/helper';
import { updateUserProfile, getFiUserById } from '../../redux/actions/users';
// import BreadCrumb from '../../components/Breadcrumbs';
import { successHandling } from '../../utils';
import { USER_DATA } from '../../redux/reducer/authReducer';
import { EDIT_IC } from '../../assets/images';
import Loader from '../Loader/Loader';
import { ProfileStyled } from './Profile.style';

const Profile = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.AuthReducer);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState({ preview: userData?.path ? userData?.path : '/logo192.png', raw: '' });
  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
      const params = {
        _id: userData?._id,
        role: 'fiuser',
        name: userData?.name,
        username: userData?.username,
        email: userData?.email,
        phone: userData?.phone
      };

      const formData = new FormData();

      for (const key in params) {
        formData.append(key, params[key]);
      }
      // if(image.raw)
      formData.append('profilePic', e.target.files[0], e.target.files[0]?.name);

      dispatch(updateUserProfile(formData)).then((res) => {
        if (res) {
          fetchUserData();
          successHandling('Profile Image updated successfully');
        }
      });
    }
  };

  const fetchUserData = () => {
    setIsLoading(true);
    getFiUserById(userData._id).then((res) => {
      if (res) {
        dispatch({
          type: USER_DATA,
          payload: { ...res.data, bankId: userData.bankId }
        });
        setIsLoading(false);
      }
    });
  };
  const formatNumber = (number) => {
    return formatPhoneNumber(number.substr(number.length - 10));
  };
  return (
    <ProfileStyled>
      <div className="container p-3">
        &nbsp;
        {/* <BreadCrumb breadData={[{ title: 'Dashboard', href: '/user' }, { title: 'Profile' }]} /> */}
        <h1 className="text-start">Profile</h1>
        <Loader isLoading={isLoading} height={100}>
          <div className="row  mx-2 px-2 my-4 shadow-xl p-4 bg-white rounded">
            <div className="col-lg-2 text-start profileimg">
              <img src={image ? image.preview : '/logo192.png'} width="135" height="135" alt="person"></img>
              <label htmlFor="upload-button" className="btn btn-info btn-sm editProfileClass">
                {/* <button className="btn btn-info btn-sm editProfileClass"> */}
                <img src={EDIT_IC} alt="profile" />
                {/* </button> */}
              </label>
              <input type="file" id="upload-button" style={{ display: 'none' }} onChange={handleChange} />
            </div>
            <div className="col-10">
              <div className="row">
                <div className="col text-start">
                  <h4>FI User</h4>
                  <h4>{userData?.name}</h4>
                </div>
                <div className="col text-start">
                  <h4>Bank Name</h4>
                  <h4>{userData?.bankId?.bankName}</h4>
                </div>
                <div className="col text-start">
                  <h4>Email</h4>
                  <h4>{userData?.email}</h4>
                </div>
              </div>
              <br></br>
              <div className="row">
                <div className="col text-start">
                  <h4>Phone number</h4>
                  <h4>{formatNumber(userData?.phone)}</h4>
                </div>
                <div className="col text-start">
                  <h4>Password</h4>
                  <h4>***************</h4>
                </div>
                <div className="col text-start"></div>
              </div>
            </div>
            <div className="row mx-1 text-end my-4">
              <div className="col-lg-12">
                {/*
                <Button variant="outline-primary" style={{ marginRight: 20 }} type="button" onClick={() => navigate('/changepassword')}>
                  Change password
                </Button>
                */}
                <Button variant="primary" type="button" onClick={() => navigate('/editprofile')}>
                  Edit Profile
                </Button>
              </div>
            </div>
          </div>
        </Loader>
      </div>
    </ProfileStyled>
  );
};

export default Profile;
