import styled from 'styled-components';

export const NotificationStyle = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: #04021d;
`;

export const NotificationWrapper = styled.div`
  width: 98%;
  background: #ffffff;
  border-radius: 10px;
  .container {
    &:hover {
      cursor: pointer;
      background-color: #ebeded;
    }
  }
  .row {
    .outterRing {
      width: 58px;
      height: 57px;
      border: 1px solid #dae1e7;
      border-radius: 50%;
      .innerRing {
        background: #12b2cb;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        position: relative;
        top: 2.9px;
        left: 2.7px;
        text-align: center;
        span {
          font-weight: 600;
          font-size: 26px;
          color: #ffffff;
          position: relative;
          top: 4px;
        }
      }
    }
    .heading {
      font-weight: 600;
      font-size: 20px;
      color: #000000;
    }
    .subHeading {
      font-weight: 400;
      font-size: 16px;
      color: #455a64;
    }
  }
  .horizontal {
    border-top: 1px solid #dae1e7;
    width: 100%;
  }
`;

export const NotificationContainer = styled.div`
  margin: 12px;
  .w-100 {
    width: 98% !important;
  }
  .reviewTable {
    margin-top: 20px;
    .plainText {
      display: flex;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
    }
  }
  .clearAll {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: #12b2cb;
    cursor: pointer;
    &:hover {
      color: #0792a8;
    }
  }
`;
