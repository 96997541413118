import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import { API_LOGOUT } from '../../constant/api';

const Logout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      dispatch(logout());
    }, 2000);
  }, []);

  return (
    <>
      <iframe
        title="Loadder"
        width="100%"
        height="0"
        src={API_LOGOUT}
        style={{ visibility: 'hidden' }}
      ></iframe>
      <h3 className="text-center mt-2">
        You are being logout
        <br /> Please wait...
      </h3>
    </>
  );
};

export default Logout;
