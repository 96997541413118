import { removeCookieByName, setDataToCookie, getDataFromCookie } from '@sbcd/wrapper/core/utils/storage/cookieHelper';
import { apiCall } from '@sbcd/wrapper/core/utils/api/api';
import { LOADING, LOGIN, LOGOUT, SET_FORGOT_PWD, VERIFY_OTP } from '../reducer/authReducer';
import { errorHandling, successHandling, resultHandling } from '../../utils';
import { API_LOGIN, API_VERIFYOTP, API_UPDATE_PASSWORD, API_RESEND_OTP, API_GET_USER_DETAILS } from '../../constant/api';
import { USER_ROLE } from '../../constant';

const catchFunction = (err, dispatch) => {
  errorHandling(err);
  dispatch({
    type: LOADING,
    payload: { isLoading: false }
  });
};

export const getAuthHeader = () => {
  if (getDataFromCookie('accessToken')) {
    return { authtoken: getDataFromCookie('accessToken'), 'Content-Type': 'application/json', Accept: 'application/json' };
  }
  return {};
};

export const removeRememberMe = () => {
  if (getDataFromCookie('rememberme')) {
    removeCookieByName('rememberme');
  }
};

export const saveRememberMe = (phone, password) => {
  setDataToCookie('rememberme', 'true:::' + phone + ':::' + password);
};

export const getRememberMe = () => {
  const data = getDataFromCookie('rememberme');
  if (data) {
    const dataArr = data.split(':::');
    const password = dataArr.slice(2);
    return { isRememberMe: dataArr[0], phone: dataArr[1], password: password.join('') };
  }
  return { isRememberMe: false, phone: '', password: '' };
};

export const getUserDetailsByToken = (token) => (dispatch) => {
  return apiCall({
    apiEndPoint: API_GET_USER_DETAILS,
    requestType: 'GET',
    header: { authtoken: token, 'Content-Type': 'application/json', Accept: 'application/json' }
  })
    .then((res) => {
      const result = res?.response;
      if (result?.code !== 200) {
        errorHandling(result?.message);
      } else if (result?.data.cdvRole !== 'fiuser') {
        errorHandling('Unauthorized access');
      } else {
        setDataToCookie('accessToken', token);
        setDataToCookie('userData', JSON.stringify({ ...result?.data, role: result?.data.cdvRole, accessToken: token }));
        dispatch({
          type: LOGIN,
          payload: result?.data
        });
        return result;
      }
    })
    .catch((err) => {
      catchFunction(err, dispatch);
    });
};

export const login = (phone, password) => (dispatch) => {
  dispatch({
    type: LOADING,
    payload: { isLoading: true }
  });
  return apiCall({ apiEndPoint: API_LOGIN, requestType: 'POST', apiPostData: { phone: phone, password: password, role: USER_ROLE } })
    .then((res) => {
      dispatch({
        type: LOADING,
        payload: { isLoading: false }
      });
      const result = res?.response;
      if (result?.code !== 200) {
        errorHandling(result?.message);
      } else if (result?.data.role !== 'fiuser') {
        errorHandling('Unauthorized access');
      } else {
        setDataToCookie('userData', JSON.stringify(result?.data));
        dispatch({
          type: LOGIN,
          payload: result?.data
        });
        return result;
      }
    })
    .catch((err) => {
      catchFunction(err, dispatch);
    });
};

export const verifyOTP = (phone, otp, deviceId) => (dispatch) => {
  dispatch({
    type: LOADING,
    payload: { isLoading: true }
  });

  return apiCall({ apiEndPoint: API_VERIFYOTP, requestType: 'POST', apiPostData: { phone: phone, otp: otp, deviceId } })
    .then((res) => {
      dispatch({
        type: LOADING,
        payload: { isLoading: false }
      });
      const result = res?.response;
      if (result?.code !== 200) {
        errorHandling(result?.message);
      } else {
        setDataToCookie('accessToken', result?.data);
        dispatch({
          type: VERIFY_OTP
        });
        return result;
      }
    })
    .catch((err) => {
      catchFunction(err, dispatch);
    });
};

export const changeUserPassword = (params) => {
  const heder = getAuthHeader();
  delete heder.authtoken;

  return apiCall({ apiEndPoint: API_UPDATE_PASSWORD, requestType: 'POST', apiPostData: params, header: heder })
    .then((res) => {
      return resultHandling(res);
    })
    .catch((err) => {
      errorHandling(err);
    });
};

export const resendOTP = (params) => {
  return apiCall({ apiEndPoint: API_RESEND_OTP, requestType: 'POST', apiPostData: params })
    .then((res) => {
      return resultHandling(res);
    })
    .catch((err) => {
      errorHandling(err);
    });
};

export const logoutByApi = () => (dispatch) => {
  removeCookieByName('accessToken');
  removeCookieByName('userData');
  dispatch({
    type: LOGOUT
  });
};

export const logout = () => (dispatch) => {
  // return apiCall({ apiEndPoint: API_LOGOUT, requestType: 'POST', header: getAuthHeader() }).then((res) => {
  //   const result = res?.response;
  //   if (result?.code !== 200) {
  //     errorHandling(result?.message);
  //   } else {
  successHandling('Logout successful.');
  removeCookieByName('accessToken');
  removeCookieByName('userData');
  dispatch({
    type: LOGOUT
  });
  // }
  // });
};

export const setForgotPwd = () => (dispatch) => {
  dispatch({
    type: SET_FORGOT_PWD,
    payload: { isForgotPwd: true }
  });
};

export const resetForgotPwd = () => (dispatch) => {
  dispatch({
    type: SET_FORGOT_PWD,
    payload: { isForgotPwd: false }
  });
};
