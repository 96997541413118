import PropsType from 'prop-types';
import { Button } from 'react-bootstrap';

const BTButtonWithLoader = ({ isLoading = false, title, loadingTitle = 'Loading...', type = 'submit', onClick = '' }) => {
  return (
    <Button type={type} variant="primary" className="text-center pr-0" disabled={isLoading} onClick={onClick}>
      {isLoading && (
        <>
          <span className="spinner-border spinner-border-sm pr-1" role="status" aria-hidden="true"></span>
          <span className="sr-only"> {loadingTitle}</span>
        </>
      )}
      {!isLoading && <>{title}</>}
    </Button>
  );
};

BTButtonWithLoader.propTypes = {
  isLoading: PropsType.bool,
  title: PropsType.string.isRequired,
  loadingTitle: PropsType.string,
  type: PropsType.string,
  onClick: PropsType.func
};

export default BTButtonWithLoader;
