import { toast } from 'react-toastify';
import { logoutByApi } from '../redux/actions/auth';
import store from '../redux/store';

export const errorHandling = (err, res) => {
  if (err?.data !== 'Invalid authentication token' || res?.code === 401) {
    store?.dispatch(logoutByApi());
    return;
  }

  if (err?.errorCode !== 401 && err?.data !== 'Invalid Guest Token') {
    if (typeof err == 'object') {
      if (Object.keys(err).length > 0) {
        toast.error(Object.values(err).join('\n'), {
          toastId: Object.values(err).join(',')
        });
      }
    } else if (typeof err == 'string') {
      toast.error(err, {
        toastId: err
      });
    } else if (err?.data?.issue?.[0]?.diagnostics) {
      toast.error(err?.data?.issue?.[0]?.diagnostics, {
        toastId: err?.data?.issue?.[0]?.diagnostics
      });
    } else if (err?.data?.apiError?.message) {
      toast.error(err?.data?.apiError?.message, {
        toastId: err?.data?.apiError?.message
      });
    }
  }
};

export const successHandling = (msg) => {
  const message = 'Success';
  if (typeof msg == 'object') {
    toast.success(msg?.join('\n'), {
      toastId: msg?.join(',')
    });
  } else if (typeof msg == 'string') {
    toast.success(msg, {
      toastId: msg
    });
  } else {
    toast.error(message, {
      toastId: message
    });
  }
};

export const formatPhoneNumber = (value) => {
  if (value.length > 14) {
    value = value.substr(value.length - 14);
  }
  let newFormat = '';
  const cleaned = ('' + value).replace(/\D/g, '');
  for (let i = 0; i < cleaned.length; i++) {
    if (i === 0) {
      newFormat = '(';
    } else if (i === 3) {
      // eslint-disable-next-line no-useless-concat
      newFormat = newFormat + ')' + ' ';
    } else if (i === 6) {
      newFormat = newFormat + '-';
    }
    newFormat = newFormat + cleaned[i];
  }
  return newFormat;
};
export const numWithZeroes = (num) => {
  return num.toLocaleString('en', { useGrouping: false, minimumFractionDigits: 2 });
};
export const makeValidURL = (urlStr) => {
  if (urlStr.indexOf('http://') !== 0 && urlStr.indexOf('https://') !== 0) {
    return 'http://' + urlStr;
  }
  return urlStr;
};

export const getFirstLetters = (nameStr = '') => {
  const nameArr = nameStr.split(' ');
  let retResult = '';
  for (let i = 0; i < nameArr.length; i++) {
    if (i === 2) break;
    retResult += nameArr[i][0];
  }
  return retResult;
};

export const formatNumberWithDecimal = (inputNumber) => {
  return Number(inputNumber)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const formatNumber = (inputNumber) => {
  return Number(inputNumber)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatBalance = (inputNumber) => {
  if (inputNumber && (inputNumber !== '' || inputNumber !== '0')) {
    return '$' + formatNumber(inputNumber);
  }
  return 'NA';
};

export const isFractionalNumber = (paramStr) => {
  const reg = /^(\d+(\.\d+)?)$/;
  return reg.test(paramStr);
};

export const isBalanceValid = (paramStr) => {
  const parmArr = paramStr.split('.');
  if (parmArr[0].length > 9) {
    return false;
  }
  return isFractionalNumber(paramStr);
};

export const numberToLocalForm = (numberFrm) => {
  return numberFrm === '' || isNaN(numberFrm) ? '' : Number(numberFrm).toLocaleString();
};

export const removeCommafromString = (strToRep) => {
  if (typeof strToRep === 'number') {
    strToRep = strToRep.toString();
  }
  return strToRep.replace(/,/g, '');
};

export const resultHandling = (res) => {
  const result = res?.response;
  if (result?.code !== 200) {
    errorHandling(result?.message);
  } else {
    return result;
  }
};

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
export const isValidatePercentage = (percentageNumber) => {
  const reg = /^(\d*(\.?\d)*)$/;
  return reg.test(parseFloat(percentageNumber)) && parseFloat(percentageNumber) <= 20;
};

export const formatRating = (starRating) => {
  if (starRating) {
    return Number(starRating).toFixed(1) || '0.0';
  }
  return 'NA';
};
