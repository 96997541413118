import { lazy, Suspense, useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '@sbcd/wrapper/core/components/Loader/Loader';

import { getFCMToken, onMessageListener } from './firebase';
import { getReviews } from './redux/actions/rateCollection';

import SideBar from './screens/Layout/SideBar';
import Header from './screens/Layout/Header';
import AddRateCollection from './screens/RCManagement/AddRateCollection';
import Profile from './screens/Profile/Profile';
import EditProfile from './screens/Profile/EditProfile';
import ChangePassword from './screens/Profile/ProfileChangePassword';
import Notification from './screens/Notification/Notification';
import Logout from './screens/Logout/Logout';
import { API_BI_URL } from './constant/api';
import { getUserDetailsByToken } from './redux/actions/auth';

const Login = lazy(() => import('./screens/Home/Login/Login'));
const Otp = lazy(() => import('./screens/Otp/Otp'));
const UserRegistration = lazy(() => import('./screens/Register/UserRegistration'));
const UserRegistrationSuccess = lazy(() => import('./screens/Register/UserRegistrationSuccess'));
const RCManagement = lazy(() => import('./screens/RCManagement/Index'));
const ForgotPassword = lazy(() => import('./screens/ForgotPassword/ForgotPassword'));

const App = () => {
  const { isLogin, userData } = useSelector((state) => state.AuthReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userData?.accessToken) {
      dispatch(getUserDetailsByToken(userData?.accessToken)).then((res) => {
        if (res) {
          if (res?.data?.isInternal) {
            window.location.href = API_BI_URL + userData?.accessToken;
          }
        }
      });
    }
  }, [userData]);

  getFCMToken();
  onMessageListener()
    .then((payload) => {
      if (isLogin) {
        toast.info(payload.notification.body);
        dispatch(
          getReviews({
            userId: userData._id,
            pageNumber: 1,
            pageSize: 5
          })
        );
      }
    })
    .catch((err) => console.error('notification failed: ', err));

  if (isLogin) {
    return (
      <Router>
        <div className="App">
          <div>
            <div className="row">
              <div className="col-2">
                <SideBar />
              </div>
              <div className="col-10 mainGB">
                <div className="row">
                  <div className="col">
                    <Header />
                  </div>
                </div>
                <div className="row">
                  <div className="col p-0">
                    <Suspense fallback={<Loader />}>
                      <Routes>
                        <Route path="/user" exact element={<RCManagement />}></Route>
                        <Route path="/ratecollection" exact element={<RCManagement />}></Route>
                        <Route path="/ratecollection/adduser" element={<AddRateCollection />}></Route>
                        <Route path="/profile" element={<Profile />}></Route>
                        <Route path="/editprofile" element={<EditProfile />}></Route>
                        <Route path="/changepassword" element={<ChangePassword />}></Route>
                        <Route path="/notification" element={<Notification />}></Route>
                        <Route path="*" element={<Navigate replace to="/user" />}></Route>
                        <Route path="/logout" element={<Logout />}></Route>
                      </Routes>
                    </Suspense>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Router>
    );
  } else {
    return (
      <Router>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Login />}></Route>
            <Route path="/otp" element={<Otp />}></Route>
            <Route path="/register" element={<UserRegistration />}></Route>
            <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
            <Route path="/registerSuccess" element={<UserRegistrationSuccess />}></Route>

            <Route path="*" element={<Navigate replace to="/" />}></Route>
          </Routes>
        </Suspense>
      </Router>
    );
  }
};

export default App;
