import { apiCall } from '@sbcd/wrapper/core/utils/api/api';
import {
  API_DELETE_RATE_COLLECTION,
  API_DELETE_REVIEW,
  API_EXPORT_RATE_COLLECTION,
  API_GET_NOTIFICATION_LIST,
  API_GET_RATE_COLLECTION,
  API_RATE_COLLECTION,
  API_UPDATE_RATE_COLLECTION,
  API_GET_RATING,
  API_GET_ALL_STATES,
  API_GET_BANK_LIST_BY_ID,
  API_GET_CD_RATE_NOTIFICATION
} from '../../constant/api';
import { errorHandling, resultHandling } from '../../utils';
import { SET_REVIEW_COUNT } from '../reducer/authReducer';
import { NOTIFICATIONS_LIST } from '../reducer/notificationReducer';
import { getAuthHeader } from './auth';

const ResultHandlingNotFound = (res) => {
  const result = res?.response;
  if (result?.code !== 200 && result?.message !== 'Data Not Found') {
    errorHandling(result?.message);
  } else {
    return result;
  }
};

export const saveRateCollection = (params) => () => {
  return apiCall({ apiEndPoint: API_RATE_COLLECTION, requestType: 'POST', apiPostData: { rateData: params }, header: getAuthHeader() })
    .then((res) => {
      return resultHandling(res);
    })
    .catch((err) => {
      errorHandling(err);
    });
};

export const getRateCollection = (params) => () => {
  return apiCall({ apiEndPoint: API_GET_RATE_COLLECTION, apiPostData: params, requestType: 'POST', header: getAuthHeader() })
    .then((res) => {
      return ResultHandlingNotFound(res);
    })
    .catch((err) => {
      errorHandling(err);
    });
};

export const getReviews = (params) => (dispatch) => {
  return apiCall({ apiEndPoint: API_GET_NOTIFICATION_LIST, apiPostData: params, requestType: 'POST', header: getAuthHeader() })
    .then((res) => {
      const result = res?.response;
      if (result?.code !== 200 && result?.message !== 'Data Not Found') {
        errorHandling(result?.message);
      } else {
        dispatch({
          type: SET_REVIEW_COUNT,
          payload: result?.data?.totalCount
        });
        if (result && result.code === 200) {
          dispatch({
            type: NOTIFICATIONS_LIST,
            payload: result?.data?.records
          });
        }
        return result;
      }
    })
    .catch((err) => {
      errorHandling(err);
    });
};

export const delReviews = (params) => (dispatch) => {
  return apiCall({ apiEndPoint: API_DELETE_REVIEW, apiPostData: params, requestType: 'POST', header: getAuthHeader() })
    .then((res) => {
      const result = res?.response;
      if (result?.code !== 200 && result?.message !== 'Data Not Found') {
        errorHandling(result?.message);
      } else {
        dispatch({
          type: SET_REVIEW_COUNT,
          payload: result?.data?.totalCount > 0
        });
        return result;
      }
    })
    .catch((err) => {
      errorHandling(err);
    });
};

export const updateRateCollection = (params) => () => {
  return apiCall({ apiEndPoint: API_UPDATE_RATE_COLLECTION, requestType: 'POST', apiPostData: params, header: getAuthHeader() })
    .then((res) => {
      return resultHandling(res);
    })
    .catch((err) => {
      errorHandling(err);
    });
};

export const deleteRateCollection = (params) => () => {
  return apiCall({ apiEndPoint: API_DELETE_RATE_COLLECTION + params, requestType: 'GET', header: getAuthHeader() })
    .then((res) => {
      return resultHandling(res);
    })
    .catch((err) => {
      errorHandling(err);
    });
};

export const exportRateCollection = (params) => () => {
  return apiCall({ apiEndPoint: API_EXPORT_RATE_COLLECTION, requestType: 'POST', apiPostData: params, header: getAuthHeader() })
    .then((res) => {
      return resultHandling(res);
    })
    .catch((err) => {
      errorHandling(err);
    });
};
export const getRating = (id) => {
  return apiCall({ apiEndPoint: API_GET_RATING + '?id=' + id, requestType: 'GET', header: getAuthHeader() })
    .then((res) => {
      return resultHandling(res);
    })
    .catch((err) => {
      errorHandling(err);
    });
};

export const getFiUserNotification = (params) => {
  return apiCall({ apiEndPoint: API_GET_CD_RATE_NOTIFICATION, apiPostData: params, requestType: 'POST', header: getAuthHeader() })
    .then((res) => {
      return resultHandling(res);
    })
    .catch((err) => {
      errorHandling(err);
    });
};

export const getAllUserState = (params) => () => {
  return apiCall({
    apiEndPoint: API_GET_ALL_STATES,
    requestType: 'POST',
    apiPostData: params
  })
    .then((res) => {
      return ResultHandlingNotFound(res);
    })
    .catch((err) => {
      errorHandling(err);
    });
};

export const getStateListById = (params) => () => {
  return apiCall({
    apiEndPoint: API_GET_BANK_LIST_BY_ID,
    requestType: 'POST',
    apiPostData: params,
    header: getAuthHeader()
  })
    .then((res) => {
      return ResultHandlingNotFound(res);
    })
    .catch((err) => {
      errorHandling(err);
    });
};
