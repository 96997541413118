import styled from 'styled-components';

export const WithLoaderStyled = styled.div`
  position: relative;
  .bluredBG {
    filter: blur(3px);
  }
  .fadeIn {
    visibility: visible;
    opacity: 1;
    transition-delay: 0ms;
  }
  .fadeOut {
    visibility: hidden;
    opacity: 0;
    transition: opacity 150ms ease-in, visibility 0ms ease-in 250ms;
  }

  .small-spinner {
    .spinner-border {
      width: 1rem;
      height: 1rem;
    }
  }
  .bigger-spinner {
    .spinner-border {
      width: 2rem;
      height: 2rem;
    }
  }
`;

export const InnerLoaderStyled = styled.div`
  position: absolute;
  animation: fade 0.4s ease-in forwards;
  width : 100%
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000011;
  color: rgb(18, 178, 203);
`;
