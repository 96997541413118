import { DEVICE_ID } from '../../constant';
export const RECEIVED = 'notification/RECEIVED';
export const UPDATE_DEVICE_TOKEN = 'notification/DEVICE_TOKEN';
export const NOTIFICATIONS_LIST = 'notification/NOTIFICATIONS_LIST';

const initialState = {
  notificationData: null,
  deviceId: DEVICE_ID,
  notification: null
};

export default function notificationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVED:
      return {
        ...state,
        notification: action?.payload
      };
    case NOTIFICATIONS_LIST:
      return {
        ...state,
        notificationData: action?.payload
      };
    case UPDATE_DEVICE_TOKEN:
      return {
        ...state,
        deviceId: action?.payload
      };
    default:
      return state;
  }
}
